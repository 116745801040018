<template>
  <div class="customNoteToMixDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.customNoteToMix.id ? '编辑' : '新增' }}销售品名转比例设置</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="日期">
            <el-date-picker :disabled="data.customNoteToMix.id > 0" style="width: 100%;" v-model="data.customNoteToMix.useDate" type="date" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="销售品名">
            <el-input v-model="data.customNoteToMix.customNote" :max="100" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">映射与配比</el-divider>
      <el-form-item label="对应仓储">
        <el-select v-model="data.customNoteToMix.storage" filterable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="配比">
        <el-table :data="data.customNoteToMix.mixList" border height="calc(100vh - 430px)">
          <el-table-column label="" width="80" header-align="center" align="center">
            <template #default="scope">
              <el-link type="danger" @click="delRow(scope.row)" v-if="scope.row.product">删除</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="product" label="煤矿品名" header-align="center">
            <template #default="scope">
              <el-select style="width: 100%;" v-model="scope.row.product" filterable @change="checkRowProduct(scope.row)">
                <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="mixNumber" label="占比" header-align="center">
            <template #default="scope">
              <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="scope.row.mixNumber" />
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  storageList: [],
  productList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "customNoteToMixDetail")
const data = reactive({
  customNoteToMix: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { mixList: [] },
})
data.customNoteToMix.mixList.push({})

api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})

const checkRowProduct = (row) => {
  const l1 = data.customNoteToMix.mixList.length
  const l2 = data.customNoteToMix.mixList.filter(item => item.product != row.product).length
  if (l1 != l2 + 1) {
    ElMessage.error("煤矿品名重复")
    row.product = null
  } else {
    data.customNoteToMix.mixList.push({})
  }
}

const delRow = (row) => {
  data.customNoteToMix.mixList = data.customNoteToMix.mixList.filter(item => item.product != row.product)
}

const save = () => {
  const param = {}
  param.useDate = util.parseTime(data.customNoteToMix.useDate, '{y}-{m}-{d}')
  param.customNote = data.customNoteToMix.customNote
  param.storage = data.customNoteToMix.storage
  param.mixList = data.customNoteToMix.mixList.filter(item => item.product)
  for (const i in param.mixList) {
    if (param.mixList[i].mixNumber == null || param.mixList[i].mixNumber <= 0) {
      ElMessage.error('第' + (1 + i * 1) + '行未填写占比')
      return
    }
  }
  if (!data.customNoteToMix.id) {
    add(param)
  } else {
    param.id = data.customNoteToMix.id
    setById(param)
  }
}

const add = (param) => {
  api.post('/backend/customNoteToMix/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = (param) => {
  api.post('/backend/customNoteToMix/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.customNoteToMixDetail {
  margin: auto;
  max-width: 900px;
}
</style>